// https://github.com/Ibaslogic/react-audio-player/blob/main/src/context/audio-player-context.tsx
import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
    RefObject,
    useRef,
    useEffect,
} from 'react';
import { apiBaseURL } from '../client/Api';
import { Transcription } from './ApiDataContext';

export interface Track {
    title: string;
    src: string;
    author: string;
    thumbnail?: string;
    transcription_id: number;
}

interface AudioPlayerContextType {
    playlist: Track[];
    setPlaylist: Dispatch<SetStateAction<Track[]>>;
    currentTrack: Track;
    setCurrentTrack: Dispatch<SetStateAction<Track>>;
    timeProgress: number;
    setTimeProgress: Dispatch<SetStateAction<number>>;
    duration: number;
    setDuration: Dispatch<SetStateAction<number>>;
    trackIndex: number;
    setTrackIndex: Dispatch<SetStateAction<number>>;
    audioRef: RefObject<HTMLAudioElement>;
    progressBarRef: RefObject<HTMLInputElement>;
    isPlaying: boolean;
    setIsPlaying: Dispatch<SetStateAction<boolean>>;
    transcriptionRecordToTrack: (transcription: Transcription) => Track;
}

const AudioPlayerContext = createContext<
    AudioPlayerContextType | undefined
>(undefined);

export const AudioPlayerProvider = ({
    children,
}: {
    children: ReactNode;
}) => {

    const transcriptionRecordToTrack = (transcription: Transcription) => {
        return {
            title: `${transcription.radio_name ?? transcription.radio_id}`,
            src: `${apiBaseURL}/recordings/mp3?transcription_id=${transcription.transcription_id}`,
            author: `${transcription.talkgroup_name ?? transcription.talkgroup_alias} (${transcription.talkgroup_id})`,
            thumbnail: transcription.logo,
            transcription_id: transcription.transcription_id,
        } as Track;
    }

    const [playlist, setPlaylist] = useState<Track[]>([]);
    const [trackIndex, setTrackIndex] = useState<number>(0);

    const [currentTrack, setCurrentTrack] = useState<Track>(playlist[trackIndex]);
    const [timeProgress, setTimeProgress] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const audioRef = useRef<HTMLAudioElement>(null);
    const progressBarRef = useRef<HTMLInputElement>(null);

    // DEBUG
    useEffect(() => {
        const data = {
            'isPlaying': isPlaying,
            'currentTrack': currentTrack,
            'trackIndex': trackIndex,
            'playlistLength': playlist.length,
            'playing': isPlaying,
        };
        // console.log(JSON.stringify(data, null, 2)
        // );
    }, [
        isPlaying,
        currentTrack,
        trackIndex,
        playlist,
    ])

    const contextValue = {
        playlist,
        setPlaylist,
        currentTrack,
        setCurrentTrack,
        audioRef,
        progressBarRef,
        timeProgress,
        setTimeProgress,
        duration,
        setDuration,
        trackIndex,
        setTrackIndex,
        isPlaying,
        setIsPlaying,
        transcriptionRecordToTrack,
    };

    return (
        <AudioPlayerContext.Provider value={contextValue}>
            {children}
        </AudioPlayerContext.Provider>
    );
};

export const useAudioPlayerContext = (): AudioPlayerContextType => {
    const context = useContext(AudioPlayerContext);

    if (context === undefined) {
        throw new Error(
            'useAudioPlayerContext must be used within an AudioPlayerProvider'
        );
    }

    return context;
};