import { Box, Fade, Paper, Popper, Stack } from '@mui/material';
import { AudioPlayer } from './audio-player/AudioPlayer';
import { TrackInfo } from './audio-player/TrackInfo';
import { useRef } from 'react';
import { useAudioPlayerContext } from '../context/AudioPlayerContext';

export default function TopBar() {
  const audioPlayerRef = useRef<HTMLElement>(null);
  const { currentTrack } = useAudioPlayerContext();

  return (
    <Box ref={audioPlayerRef} sx={{ zIndex: 'top' }} >
      <Stack direction='row'>
        <AudioPlayer />
      </Stack>
      <Popper
        open={currentTrack?.title !== ''}
        anchorEl={audioPlayerRef.current}
        placement="bottom-end"
        
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={3}>
              <TrackInfo />
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box >
  )
}
