
import React, { useEffect, useState } from "react";

import { DataGrid, GridCallbackDetails, GridColDef, GridEventListener, gridFilteredSortedRowEntriesSelector, gridPageSelector, gridPageSizeSelector, gridPaginatedVisibleSortedGridRowEntriesSelector, GridRowId, GridRowParams, MuiEvent, useGridApiRef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Avatar, Badge, Box, darken, Divider, lighten, Menu, MenuItem, Stack, styled, Theme, Tooltip, Typography } from "@mui/material";
import { useStateContext } from "../context/StateContext";
import { Transcription, useApiDataContext } from "../context/ApiDataContext";
import { useAudioPlayerContext } from "../context/AudioPlayerContext";

import { colors } from '@mui/material';
import InformationModal from "./InformationModal";
export interface TranscriptionsDataGridParams {
}

interface Color {
    [key: string]: string
}

let colorList = [
    colors.amber,
    colors.blue,
    colors.blueGrey,
    // colors.brown,
    colors.cyan,
    colors.deepOrange,
    colors.deepPurple,
    colors.green,
    // colors.grey,
    colors.indigo,
    colors.lightBlue,
    colors.lightGreen,
    colors.lime,
    colors.orange,
    // colors.pink,
    colors.purple,
    colors.red,
    colors.teal,
    // colors.yellow,
] as Color[];

const randomColorList = colorList
    .flatMap((c) =>
        [300, 500, 700, 900]
            .map(x => x.toString())
            .map(i => c[i])
    ) as string[];

const getBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
    backgroundColor: darken(color, coefficient),
    ...theme.applyStyles('light', {
        backgroundColor: lighten(color, coefficient),
    }),
});

const getColorPickerStyles = (theme: Theme) => {
    let styles: { [k: string]: any } = {};
    randomColorList.forEach((randomColor, idx) => {
        styles[`& .transcription-table--RandomColor-${idx}`] = {
            ...getBackgroundColor(randomColor, theme, 0.7),
            '&:hover': {
                ...getBackgroundColor(randomColor, theme, 0.6),
            },
            '&.Mui-selected': {
                ...getBackgroundColor(randomColor, theme, 0.5),
                '&:hover': {
                    ...getBackgroundColor(randomColor, theme, 0.4),
                },
            },
        }
    });

    return styles;
}
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .transcription-table--Playing': {
        'border': `2px solid ${theme.palette.success.main}`,
    },
    ...getColorPickerStyles(theme),
}));


function TranscriptionsDataGrid(params: TranscriptionsDataGridParams) {
    const {
        selectedTalkgroupIds,
        isTranscriptionIncluded,
        setLiveStream,
        followStream,
        setFollowStream,
    } = useStateContext();

    const {
        transcriptions
    } = useApiDataContext();

    const {
        setPlaylist,
        transcriptionRecordToTrack,
        setCurrentTrack,
        setTrackIndex,
        setIsPlaying,
        currentTrack,
    } = useAudioPlayerContext();

    const [rows, setRows] = useState<Transcription[]>([]);
    const [informationModalOpen, setInformationModalOpen] = React.useState(false);
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const [selectedRow, setSelectedRow] = React.useState<number>();

    let cr_emoji = '\u{23CE}';

    const apiRef = useGridApiRef();


    const handleScrollPositionChange: GridEventListener<'scrollPositionChange'> = (
        params,  // GridScrollParams
        _event,   // MuiEvent<React.UIEvent | MuiBaseEvent>
        _details, // GridCallbackDetails
    ) => {
        if (followStream) {
            if (currentTrack?.title) {
                let visibleRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
                const rowIndex = visibleRows.findIndex(
                    (row) => row.id === currentTrack?.transcription_id,
                );

                if (params.renderContext) {
                    // If there are bugs on page changing. Just extend this a bit.
                    if (params.renderContext.firstRowIndex-1 > rowIndex || params.renderContext.lastRowIndex+1 < rowIndex) {
                        setFollowStream(false);
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        return apiRef.current.subscribeEvent(
            'scrollPositionChange',
            handleScrollPositionChange,
        );
    }, [
        apiRef,
        handleScrollPositionChange,
    ]);

    useEffect(() => {
        if (followStream && currentTrack?.transcription_id) {
            const currentTranscriptionId = currentTrack?.transcription_id;
            const currentPage = gridPageSelector(apiRef);
            const pageSize = gridPageSizeSelector(apiRef);
            const currentOffset = gridFilteredSortedRowEntriesSelector(apiRef).map(r => r.id as number).findIndex((row) => row === currentTranscriptionId);
            const targetPage = Math.floor(currentOffset / pageSize);
            const targetRowIndex = currentOffset % pageSize;

            if (currentPage !== targetPage) {
                apiRef.current.setPage(targetPage);
                apiRef.current.forceUpdate();
            }
            apiRef.current.scrollToIndexes({ rowIndex: currentOffset });
            apiRef.current.forceUpdate();
        };

    }, [
        currentTrack,
        followStream,
        apiRef
    ]);


    useEffect(() => {
        (async () => {
            const newRows = transcriptions
                .filter(t => isTranscriptionIncluded(t));
            setRows(newRows);
        })()
    }, [
        isTranscriptionIncluded,
        selectedTalkgroupIds,
        transcriptions,
    ]);

    const columns: GridColDef[] = [
        {
            field: 'logo',
            headerName: '',
            width: 140,
            sortable: false,
            hideable: false,
            renderCell: (cellValues) => {
                const row = cellValues.row as Transcription;
                const primaryLogo = row.radio_logo || row.agency_logo;
                const secondaryLogo = row.talkgroup_logo || primaryLogo;
                return (
                    <Stack direction='column'>
                        <Stack direction='row'>
                            <Box>
                                <Tooltip title={cellValues.row.talkgroup_alias}>
                                    {
                                        (primaryLogo && primaryLogo !== secondaryLogo) ?
                                            <Badge
                                                overlap="circular"

                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <Avatar
                                                        src={`${process.env.PUBLIC_URL}/${secondaryLogo}`}
                                                        sx={{ width: 22, height: 22, fontSize: 8 }}
                                                    />
                                                }
                                            >
                                                <Avatar
                                                    alt="Hello"
                                                    src={`${process.env.PUBLIC_URL}/${primaryLogo}`}
                                                    sx={{ border: row.radio_dispatcher ? "3px solid red" : undefined }}
                                                />
                                            </Badge>
                                            :
                                            <Avatar
                                                src={`${process.env.PUBLIC_URL}/${secondaryLogo}`}
                                                sx={{ border: row.radio_dispatcher ? "3px solid red" : undefined }}
                                            />

                                    }
                                </Tooltip>
                            </Box>
                            <Stack direction='column'
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Typography align="right" variant='caption'>{dayjs(row.timestamp).format('LTS')}</Typography>
                                <Typography align="right" variant='caption'>{dayjs(row.timestamp).format('L')}</Typography>
                            </Stack>

                        </Stack>
                        <Box paddingTop={"4px"}>
                            <Typography
                                variant='body2'
                                style={{
                                    whiteSpace: 'pre-line',
                                    textAlign: 'center'
                                }}
                            >
                                {row.radio_name ?? (row.radio_id ? `${row.radio_id}` : '')}
                            </Typography>
                        </Box>
                    </Stack>

                )
            },

        },
        {
            field: 'transcription',
            headerName: 'Transcription',
            sortable: false,
            hideable: false,
            // width: 240,
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>{cellValues.row.transcription.replace('\n', `${cr_emoji}\n`).slice(0, -2)}</Typography>
                )
            }
        },
    ];


    // debug
    function handleRowClick(params: GridRowParams<any>, event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>, details: GridCallbackDetails<any>): void {
        console.log(`handleRowClick transcription_id: ${params.row.transcription_id}`)

        const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
        const selectedRows = Array.from(apiRef.current.getSelectedRows().values());
        if (!selectedRows) {
            return;
        }
        const rowIndex = allRows.findIndex(
            (row) => row.id === params.row.transcription_id
        );

        console.log(`handleRowClick rowIndex: ${rowIndex}`)

    }

    function getRowId(row: any): GridRowId {
        return row.transcription_id
    }


    const createPlaylist = (transcription_id: number, count?: number) => {
        const newPlaylist = transcriptions
            .filter(t => t.transcription_id >= transcription_id)
            .filter(t => isTranscriptionIncluded(t))
            .toSorted((a, b) => a.transcription_id - b.transcription_id) // asc order
            .filter((_t, idx) => !count || idx < count)
            .map(t => { return transcriptionRecordToTrack(t); });

        setPlaylist(newPlaylist);
        setCurrentTrack(newPlaylist[0]);
        setTrackIndex(0);

        if (!count) {
            setLiveStream(true);
        }
        setIsPlaying(true);
    }

    const handlePlay = (count?: number) => {
        if (selectedRow != null) {
            createPlaylist(selectedRow, count);
        }
        handleContextMenuClose();
    }


    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    function handleContextMenuClose() {
        setContextMenu(null);
    }

    return (
        <React.Fragment>
            <StyledDataGrid
                sx={{
                    overflowX: 'scroll',
                    display: 'flex',
                    overflow: "hidden",
                }}
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                columnHeaderHeight={0}
                // slots={{
                //     columnHeaders: () => null,
                // }}
                slotProps={{
                    row: {
                        onContextMenu: handleContextMenu,
                        onDoubleClick: handleContextMenu,
                        style: { cursor: 'context-menu' },
                    }
                }}
                onRowClick={handleRowClick}
                getRowId={getRowId}
                getCellClassName={(params) =>
                    [
                        params.field === 'logo' && params.row.radio_id && `transcription-table--RandomColor-${params.row.radio_id % randomColorList.length}`
                    ].filter(x => x).join(' ')

                }
                getRowClassName={(params) =>
                    [
                        params.row.transcription_id === currentTrack?.transcription_id && 'transcription-table--Playing',
                    ].filter(x => x).join(' ')}
            />
            <Menu
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                slotProps={{
                    root: {
                        onContextMenu: (event) => {
                            event.preventDefault();
                            handleContextMenuClose();
                        },
                    },
                }}
            >
                <MenuItem onClick={() => handlePlay(1)}>Play (1)</MenuItem>
                <MenuItem onClick={() => handlePlay(3)}>Play (3)</MenuItem>
                <MenuItem onClick={() => handlePlay(5)}>Play (5)</MenuItem>
                <MenuItem onClick={() => { handlePlay(undefined); setFollowStream(true); }}>Play All</MenuItem>
                <Divider />
                <MenuItem onClick={() => setInformationModalOpen(true)}>Infomation</MenuItem>
            </Menu>
            <InformationModal
                informationModalOpen={informationModalOpen}
                setInfomationModalOpen={setInformationModalOpen}
                selectedRow={selectedRow}
            />
        </React.Fragment>
    );
}

export default TranscriptionsDataGrid;


