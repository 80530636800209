import './App.css';
import { Box, createTheme, CssBaseline, Divider, ThemeProvider, useMediaQuery } from '@mui/material';
import TranscriptionsDataGrid from './components/TranscriptionsDataGrid';
import React, { useEffect } from 'react';
import BottomAppBar from './components/BottomAppBar';
import { useAudioPlayerContext } from './context/AudioPlayerContext'
import { useApiDataContext } from './context/ApiDataContext';
import { useStateContext } from './context/StateContext';
import TopBar from './components/TopBar';
import { usePrevious } from 'react-use';
import useWakeLock from 'react-use-wake-lock';

export default function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const {
    playlist,
    setPlaylist,
    transcriptionRecordToTrack,
    currentTrack,
    setCurrentTrack,
    setTrackIndex
  } = useAudioPlayerContext();

  const {
    lastTranscription,
    refreshedTranscriptions,
    maxTranscriptionId,
  } = useApiDataContext();

  const previousLastTranscription = usePrevious(lastTranscription);

  const {
    liveStream,
    isTranscriptionIncluded,
  } = useStateContext();

  const { isSupported, isLocked, request, release } = useWakeLock();

  useEffect(() => {
    if (lastTranscription && lastTranscription !== previousLastTranscription && liveStream && isTranscriptionIncluded(lastTranscription)) {
      const newTrack = transcriptionRecordToTrack(lastTranscription);
      const newPlaylist = [...playlist, newTrack];
      setPlaylist(newPlaylist);
      if (!currentTrack) {
        setCurrentTrack(newTrack)
        setTrackIndex(newPlaylist.length - 1)
      }
    }
  }, [
    lastTranscription,
    liveStream,
    playlist,
    currentTrack,
    previousLastTranscription,
    setPlaylist,
    setTrackIndex,
    transcriptionRecordToTrack,
    isTranscriptionIncluded,
    setCurrentTrack,
  ]);

  useEffect(() => {
    if (liveStream) {
      const maxPlaylistTransactionId = Math.max(...playlist.map(t => t.transcription_id))
      const newTracks = refreshedTranscriptions
        .filter(t => t.transcription_id > maxPlaylistTransactionId)
        .filter(t => isTranscriptionIncluded(t))
        .toSorted((a, b) => a.transcription_id - b.transcription_id) // asc
        .map(t => transcriptionRecordToTrack(t));

      if (newTracks.length > 0) {
        setPlaylist([...playlist, ...newTracks])
      }
    }
  }, [
    refreshedTranscriptions,
    liveStream,
    playlist,
    currentTrack,
    previousLastTranscription,
    setPlaylist,
    setTrackIndex,
    transcriptionRecordToTrack,
    isTranscriptionIncluded,
    setCurrentTrack,
  ]);

  useEffect(() => {
    console.log(`Wake lock is ${!isSupported && "not"} supported.`)
    if (liveStream) {
      request();
    }
    else if (isLocked && !liveStream) {
      release();
    }

  }, [
    liveStream,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
          <TopBar />
          <Divider />
          <Box
            component='main'
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
          }}            >
            <TranscriptionsDataGrid />
          </Box>
          <BottomAppBar />
      </div>
    </ThemeProvider>
  );
}