import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react';
import Cookies from 'js-cookie';
import { Transcription } from './ApiDataContext';

function getCookie<T>(cookie: string, defaultValue:T|undefined = undefined):T|undefined {
    const data = Cookies.get(cookie);
    if (!data) {
        return defaultValue;
    }
    try {
        const parsed = JSON.parse(data) as T;
        return parsed;
    } catch (e: any) { 
        return defaultValue;
    }
}

const setCookie = (cookie: string, value: {} | []) => {
    const data = JSON.stringify(value);
    Cookies.set(
        cookie, `${data}`,
        {
            sameSite: 'none',
            expires: 7,
            path: "/",
            secure: true,
        }
    );
    console.debug(`set cookie (${cookie}): ${data}`);
}

interface StateContextType {
    selectedTalkgroupIds: Set<number>;
    setSelectedTalkgroupIds: Dispatch<SetStateAction<Set<number>>>;
    favoriteTalkgroupIds: Set<number>;
    setFavoriteTalkgroupIds: Dispatch<SetStateAction<Set<number>>>;
    selectedRadioIds: Set<number>;
    setSelectedRadioIds: Dispatch<SetStateAction<Set<number>>>;
    liveStream: boolean;
    setLiveStream: Dispatch<SetStateAction<boolean>>;
    isTranscriptionIncluded: (t: Transcription) => boolean;
    followStream: boolean;
    setFollowStream: Dispatch<SetStateAction<boolean>>;
}

const StateContext = createContext<
    StateContextType | undefined
>(undefined);

export const StateProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [selectedTalkgroupIds, setSelectedTalkgroupIds] = useState(new Set<number>(getCookie("selectedTalkgroupIds", []) || []));
    const [favoriteTalkgroupIds, setFavoriteTalkgroupIds] = useState(new Set<number>(getCookie("favoriteTalkgroupIds", []) || []));
    const [selectedRadioIds, setSelectedRadioIds] = useState(new Set<number>(getCookie("selectedRadioIds", []) || []));
    const [liveStream, setLiveStream] = useState(false);
    const [followStream, setFollowStream] = useState(false);

    const isTranscriptionIncluded = (t: Transcription) => {
        return (
            (selectedTalkgroupIds.size === 0 || selectedTalkgroupIds.has(t.talkgroup_id ?? -1))
            &&
            (selectedRadioIds.size === 0 || selectedRadioIds.has(t.radio_id ?? -1))
        );
    }

    const contextValue = {
        selectedTalkgroupIds,
        setSelectedTalkgroupIds,
        favoriteTalkgroupIds,
        setFavoriteTalkgroupIds,
        selectedRadioIds,
        setSelectedRadioIds,
        liveStream,
        setLiveStream,
        isTranscriptionIncluded,
        followStream,
        setFollowStream,
    };

    useEffect(() => {
        const list = Array.from(selectedTalkgroupIds.values()) || [];
        setCookie("selectedTalkgroupIds", list);
    }, [selectedTalkgroupIds]);

    useEffect(() => {
        const list = Array.from(favoriteTalkgroupIds.values()) || [];
        setCookie("favoriteTalkgroupIds", list);
    }, [favoriteTalkgroupIds]);

    useEffect(() => {
        const list = Array.from(selectedRadioIds.values()) || [];
        setCookie("selectedRadioIds", list);
    }, [selectedRadioIds]);

    return (
        <StateContext.Provider value={contextValue}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = (): StateContextType => {
    const context = useContext(StateContext);

    if (context === undefined) {
        throw new Error(
            'useStateContext must be used within an StateProvider'
        );
    }

    return context;
};