import { SdrTrunkApi } from "./generated"

function getBaseUrl(): string|undefined {
    console.log(window.location.origin);
    if (window.location.protocol == "https") {
        return `${window.location.origin}/api/v1`;
    }
    return process.env.REACT_APP_API_BASEURL ?? 'http://localhost:8124';
}

let apiBaseURL = getBaseUrl();
let api = new SdrTrunkApi({ BASE: apiBaseURL })

export { apiBaseURL, api }
