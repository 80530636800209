import * as React from 'react';

import { Modal, Box, Typography } from '@mui/material';
import { useApiDataContext } from '../context/ApiDataContext';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface InfomationModalProps {
    informationModalOpen: boolean,
    setInfomationModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    selectedRow: number | undefined,
}


export default function InfomationModal(props: InfomationModalProps) {
    const handleClose = () => props.setInfomationModalOpen(false);

    const {
        transcriptions
    } = useApiDataContext();

    const transcription = transcriptions.filter(t => t.transcription_id === props.selectedRow)[0];

    return (
        <Modal
            open={props.informationModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant='h6'>Transcription ({transcription?.transcription_id})</Typography>
                <Typography>{transcription?.timestamp}</Typography>
                <Typography>{transcription?.transcription}</Typography>
                <Typography>Logo {transcription?.logo}</Typography>
                <Typography variant='h6'>Talkgroup</Typography>
                <Typography>Id {transcription?.talkgroup_id}</Typography>
                <Typography>Alias {transcription?.talkgroup_alias}</Typography>
                <Typography>Name {transcription?.talkgroup_name}</Typography>
                <Typography>Logo {transcription?.talkgroup_logo}</Typography>
                <Typography variant='h6'>Radio</Typography>
                <Typography>Id {transcription?.radio_id}</Typography>
                <Typography>Name {transcription?.radio_name}</Typography>
                <Typography>Logo {transcription?.radio_logo}</Typography>
            </Box>
        </Modal>
    );
}
