import { Transcription } from "./context/ApiDataContext";

export interface TalkgroupStatisticsValue {
    transcription_ids: Set<number>,
    radio_ids: Set<number>,
}

export interface RadioStatisticsValue {
    transcription_ids: Set<number>,
    talkgroup_ids: Set<number>,
}

export interface TalkgroupStatisticsMapProto {
    [talkgroup_id: number]: TalkgroupStatisticsValue
}

export type TalkgroupStatisticsMap = Map<number, TalkgroupStatisticsValue>;
export type RadioStatisticsMap = Map<number, RadioStatisticsValue>;

export default class ClientData {
    constructor() {
        this.talkgroupStatistics = new Map() as TalkgroupStatisticsMap;
        this.radioStatistics = new Map() as RadioStatisticsMap;
    }

    talkgroupStatistics: TalkgroupStatisticsMap;
    radioStatistics: RadioStatisticsMap;


    updateStatistics = (transcriptions: Transcription[], merge: boolean = true) => {
        this.updateTalkgroupStatistics(transcriptions, merge);
        this.updateRadioStatistics(transcriptions, merge);
    }

    updateTalkgroupStatistics = (transcriptions: Transcription[], merge: boolean = true) => {
        const newTalkgroupStatistics: TalkgroupStatisticsMap = merge ? new Map(this.talkgroupStatistics) : new Map();

        for (const record of transcriptions) {
            if (!record.talkgroup_id) continue;

            if (!newTalkgroupStatistics.has(record.talkgroup_id)) {
                newTalkgroupStatistics.set(record.talkgroup_id, { transcription_ids: new Set(), radio_ids: new Set() });
            }

            const currentTalkgroup = newTalkgroupStatistics.get(record.talkgroup_id);
            currentTalkgroup?.transcription_ids.add(record.transcription_id);
            record.radio_id && currentTalkgroup?.radio_ids.add(record.radio_id);
        }
        this.talkgroupStatistics = newTalkgroupStatistics;
    }

    updateRadioStatistics = (transcriptions: Transcription[], merge: boolean = true) => {
        const newRadioStatistics: RadioStatisticsMap = merge ? new Map(this.radioStatistics) : new Map();

        transcriptions
            .forEach(record => {
                if (!record.radio_id) return;
                if (!newRadioStatistics.has(record.radio_id)) {
                    newRadioStatistics.set(record.radio_id, { transcription_ids: new Set(), talkgroup_ids: new Set() });
                }

                const currentRadio = newRadioStatistics.get(record.radio_id);
                currentRadio?.transcription_ids.add(record.transcription_id);
                currentRadio?.talkgroup_ids.add(record.talkgroup_id);
            });

        this.radioStatistics = newRadioStatistics;
    }
}