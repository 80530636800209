import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react';
import {
    AgencyRecord,
    AgencyRequest,
    RadioRecord,
    TalkgroupRecord,
    TalkgroupRequest,
    TimeRange,
    TranscriptionRecord,
    TranscriptionRequest
} from '../client/generated';
import dayjs from 'dayjs';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { api } from '../client/Api';
import { AxiosError } from 'axios';
import ClientData, { RadioStatisticsMap, TalkgroupStatisticsMap } from '../ClientData';

export interface Transcription {
    transcription_id: number;
    timestamp: string;
    duration: number;
    site_id: number | null;
    talkgroup_id: number;
    talkgroup_alias: string | null;
    talkgroup_name: string | null;
    talkgroup_logo: string | null;
    agency_name: string | null;
    agency_call_sign: string | null;
    agency_logo: string | null;
    radio_id: number | null;
    radio_name: string | null;
    radio_logo: string | null;
    radio_dispatcher: boolean;
    transcription: string;
    logo: string | null;
}

interface MqttMessage {
    type: string,
    data: TranscriptionRecord
}

interface ApiDataContextType {
    transcriptions: Transcription[];
    refreshedTranscriptions: Transcription[];
    lastTranscription: Transcription | undefined;
    maxTranscriptionId: number;
    talkgroups: TalkgroupRecord[];
    talkgroupMap: Map<number | null, TalkgroupRecord>;
    talkgroupStatistics: TalkgroupStatisticsMap;
    radios: RadioRecord[];
    radioMap: Map<number | null, RadioRecord>;
    radioStatistics: RadioStatisticsMap;
    beginTime: dayjs.Dayjs | undefined;
    setBeginTime: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>;
    endTime: dayjs.Dayjs | undefined;
    setEndTime: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>;
}

const ApiDataContext = createContext<
    ApiDataContextType | undefined
>(undefined);

export const ApiDataProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [initialLoad, setInitialLoad] = useState(true)
    const [reloadData] = useState(0)
    const [transcriptions, setTranscriptions] = useState<Transcription[]>([]);
    const [refreshedTranscriptions, setRefreshedTranscriptions] = useState<Transcription[]>([]);
    const [lastTranscription, setLastTranscription] = useState<Transcription | undefined>(undefined);
    const [talkgroups, setTalkgroups] = useState<TalkgroupRecord[]>([]);
    const [talkgroupMap, setTalkgroupMap] = useState<Map<number | null, TalkgroupRecord>>(new Map());
    const [agencies, setAgencies] = useState<AgencyRecord[]>([]);
    const [agencyMap, setAgencyMap] = useState<Map<number | null, AgencyRecord>>(new Map());
    const [radios, setRadios] = useState<RadioRecord[]>([]);
    const [radioMap, setRadioMap] = useState<Map<number | null, RadioRecord>>(new Map());
    const [maxTranscriptionId, setMaxTranscriptionId] = useState(0);
    const [beginTime, setBeginTime] = useState<dayjs.Dayjs | undefined>(dayjs(Date.now()).subtract(2, 'day'));
    const [endTime, setEndTime] = useState<dayjs.Dayjs | undefined>(undefined);
    const [clientData] = useState<ClientData>(new ClientData());
    const { lastJsonMessage, readyState } = useWebSocket<MqttMessage>(`${process.env.REACT_APP_API_BASEURL}/ws`, {
        share: false,
        shouldReconnect: (closeEvent) => true,
    });
    const [webSocketPreviouslyConnected, setWebSocketPreviouslyConnected] = useState(false);

    const contextValue = {
        transcriptions,
        refreshedTranscriptions,
        lastTranscription,
        maxTranscriptionId,
        talkgroupStatistics: clientData.talkgroupStatistics,
        talkgroups,
        talkgroupMap,
        agencies,
        agencyMap,
        radioStatistics: clientData.radioStatistics,
        radios,
        radioMap,
        beginTime,
        setBeginTime,
        endTime,
        setEndTime,
    };

    const enrichTranscription = (
        transcription: TranscriptionRecord,
        myRadioMap: Map<number | null, RadioRecord> = radioMap,
        myAgencyMap: Map<number | null, AgencyRecord> = agencyMap,
        myTalkgroupMap: Map<number | null, TalkgroupRecord> = talkgroupMap,
    ) => {
        const radio = myRadioMap?.get(transcription.radio_id);
        const agency = myAgencyMap?.get(radio?.agency_id ?? null);
        const talkgroup = myTalkgroupMap?.get(transcription.talkgroup_id);

        return {
            ...transcription,
            talkgroup_alias: talkgroup?.alias,
            talkgroup_logo: talkgroup?.logo,
            talkgroup_name: talkgroup?.name,
            agency_name: agency?.name,
            agency_call_sign: agency?.call_sign,
            agency_logo: agency?.logo,
            radio_name: radio?.name,
            radio_logo: radio?.logo,
            radio_dispatcher: radio?.radio_type_id === 1,
            logo: radio?.logo ?? agency?.logo ?? talkgroup?.logo

        } as Transcription;

    }

    const fetchData = () => {
        Promise.all([
            api.talkgroups.getTalkgroupsTalkgroupsPost(
                {
                    requestBody:
                        {
                            time_range: {
                                min: beginTime ? beginTime.toISOString() : null,
                                max: endTime ? endTime.toISOString() : null,
                            } as TimeRange,
                        } as TalkgroupRequest
                }
            )
                .then((response) => {
                    const items = response.talkgroups ?? []
                    setTalkgroups(items);
                    const newTalkgroupMap = new Map(items.map(obj => [obj.talkgroup_id, obj]));
                    setTalkgroupMap(newTalkgroupMap);
                    return newTalkgroupMap;
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                    return undefined;
                }),
            api.agencies.getAgenciesAgenciesPost(
                {
                    requestBody:
                        {
                        } as AgencyRequest
                }
            )
                .then((response) => {
                    const items = response.agencies ?? []
                    setAgencies(items);
                    const newAgencyMap = new Map(items.map(obj => [obj.agency_id as number, obj]));
                    setAgencyMap(newAgencyMap);
                    return newAgencyMap;
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                    return undefined;
                }),
            api.radios.getRadiosRadiosPost(
                {
                    requestBody:
                        {
                            time_range: {
                                min: beginTime ? beginTime.toISOString() : null,
                                max: endTime ? endTime.toISOString() : null,
                            } as TimeRange,
                        } as TalkgroupRequest
                }
            )
                .then((response) => {
                    const items = response.radios ?? []
                    setRadios(items);
                    const newRadioMap = new Map(items.map(obj => [obj.radio_id as number | null, obj]));
                    setRadioMap(newRadioMap);
                    return newRadioMap;
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                    return undefined;
                }),
        ]).then(([newTalkgroupMap, newAgencyMap, newRadioMap]) => {
            api.transcriptions.getTranscriptionsTranscriptionsPost(
                {
                    requestBody: {
                        radio_ids: null,
                        talkgroup_ids: null,
                        limit: beginTime ? 100_000 : 500,
                        joined: false,
                        time_range: {
                            min: beginTime ? beginTime.toISOString() : null,
                            max: endTime ? endTime.toISOString() : null,
                        } as TimeRange,
                        transcription_range: {
                            min: maxTranscriptionId,
                        },
                    } as TranscriptionRequest
                })
                .then((response) => response.transcriptions ?? [])
                .then((newTranscriptions) => newTranscriptions.map(transcription => {
                    return enrichTranscription(transcription, newRadioMap, newAgencyMap, newTalkgroupMap);
                }))
                .then((newTranscriptions) => {
                    const refreshed = maxTranscriptionId > 0;

                    if (refreshed) {
                        setRefreshedTranscriptions(newTranscriptions.filter(t => t.transcription_id > maxTranscriptionId));
                    }

                    clientData.updateStatistics(newTranscriptions, maxTranscriptionId > 0);
                    setTranscriptions(refreshed ? newTranscriptions.concat(transcriptions) : newTranscriptions);

                    if (newTranscriptions.length > 0) {
                        const newMaxTranscriptionId = Math.max(...(newTranscriptions.map(x => x.transcription_id)));
                        setMaxTranscriptionId(newMaxTranscriptionId);
                    }
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                })
        })
    }

    useEffect(() => {
        console.log(`ApiData Websocket State: ${ReadyState[readyState]}`);
        if (readyState === ReadyState.OPEN) {
            if (webSocketPreviouslyConnected) {
                fetchData();
            }
            else {
                setWebSocketPreviouslyConnected(true);
            }
        }
    }, [readyState]);

    useEffect(() => {
        if (initialLoad || reloadData) {
            setInitialLoad(false)
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData, initialLoad]);

    const receiveSocketMessage = (message: MqttMessage) => {
        console.log(`WS Message Received ${JSON.stringify(message)}`);

        const transcription = enrichTranscription(message.data);

        if (transcription.transcription_id > maxTranscriptionId) {
            setTranscriptions([transcription, ...transcriptions]);
            clientData.updateStatistics([transcription], true);
            setMaxTranscriptionId(transcription.transcription_id);
            setLastTranscription(transcription);
        } else {
            console.debug(`Already received transcription_id: ${transcription.transcription_id}`)
        }
    }

    useEffect(() => {
        if (lastJsonMessage) {
            receiveSocketMessage(lastJsonMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastJsonMessage]);

    return (
        <ApiDataContext.Provider value={contextValue}>
            {children}
        </ApiDataContext.Provider>
    );
};

export const useApiDataContext = (): ApiDataContextType => {
    const context = useContext(ApiDataContext);

    if (context === undefined) {
        throw new Error(
            'useApiDataContext must be used within an ApiDataProvider'
        );
    }

    return context;
};