import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AgenciesService } from './services.gen';
import { RadiosService } from './services.gen';
import { RecordingsService } from './services.gen';
import { SitesService } from './services.gen';
import { TalkgroupsService } from './services.gen';
import { TranscriptionsService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class SdrTrunkApi {

	public readonly agencies: AgenciesService;
	public readonly radios: RadiosService;
	public readonly recordings: RecordingsService;
	public readonly sites: SitesService;
	public readonly talkgroups: TalkgroupsService;
	public readonly transcriptions: TranscriptionsService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '0.1.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.agencies = new AgenciesService(this.request);
		this.radios = new RadiosService(this.request);
		this.recordings = new RecordingsService(this.request);
		this.sites = new SitesService(this.request);
		this.talkgroups = new TalkgroupsService(this.request);
		this.transcriptions = new TranscriptionsService(this.request);
	}
}
