import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { TalkgroupRecord } from "../client/generated";
import { Button, Modal, Box, Checkbox, Typography } from '@mui/material';
import { useStateContext } from '../context/StateContext';
import { useApiDataContext } from '../context/ApiDataContext';
import { FavoriteBorder, Favorite } from '@mui/icons-material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface TalkgroupsModalParams {
    talkgroupsModalOpen: boolean,
    setTalkgroupsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

function TalkgroupsModal(params: TalkgroupsModalParams) {
    const {
        selectedTalkgroupIds,
        setSelectedTalkgroupIds,
        favoriteTalkgroupIds,
        setFavoriteTalkgroupIds,
    } = useStateContext();
    const {
        talkgroups,
        talkgroupStatistics,
    } = useApiDataContext();
    const handleTalkgroupsClose = () => params.setTalkgroupsModalOpen(false);

    let tx_emoji = '\u{1F4AC}';
    let radio_emoji = '\u{1F399}';

    const handleClearTalkgroups = () => {
        const newSelectedTalkGroupIds = new Set<number>();
        setSelectedTalkgroupIds(newSelectedTalkGroupIds)
    }

    const handleListToFavorites = () => {
        setSelectedTalkgroupIds(selectedTalkgroupIds.union(favoriteTalkgroupIds))
    }

    const toggleSelectedTalkgroup = (value: number) => () => {
        const newSelectedTalkGroupIds = new Set<number>(selectedTalkgroupIds);
        newSelectedTalkGroupIds.has(value) ? newSelectedTalkGroupIds.delete(value) : newSelectedTalkGroupIds.add(value);
        setSelectedTalkgroupIds(newSelectedTalkGroupIds);
    };

    const toggleFavoriteTalkgroup = (value: number) => () => {
        const newFavoriteTalkGroupIds = new Set<number>(favoriteTalkgroupIds);
        newFavoriteTalkGroupIds.has(value) ? newFavoriteTalkGroupIds.delete(value) : newFavoriteTalkGroupIds.add(value);
        setFavoriteTalkgroupIds(newFavoriteTalkGroupIds);
    };

    function renderTalkgroupListItems(predicate?: (value: TalkgroupRecord, index: number, array: TalkgroupRecord[]) => unknown) {
        return (
            <React.Fragment>
                {
                    talkgroups
                        .map((value) => {
                            return {
                                ...value,
                                radio_count: talkgroupStatistics.get(value.talkgroup_id)?.radio_ids?.size ?? 0,
                                tx_count: talkgroupStatistics.get(value.talkgroup_id)?.transcription_ids?.size ?? 0,
                            } as TalkgroupRecord
                        })
                        .filter(value => value.tx_count ?? 0 > 0)
                        .toSorted((a, b) => (a.tx_count ?? 0) < (b.tx_count ?? 0) ? -1 : (a.tx_count ?? 0) < (b.tx_count ?? 0) ? 1 : 0)
                        .reverse()
                        .filter(predicate ?? (() => true))
                        .map((value) => {
                            return {
                                ...value,
                                radio_count: talkgroupStatistics.get(value.talkgroup_id)?.radio_ids?.size ?? 0,
                                tx_count: talkgroupStatistics.get(value.talkgroup_id)?.transcription_ids?.size ?? 0,
                            } as TalkgroupRecord
                        })
                        .toSorted((a, b) => (a.tx_count ?? 0) < (b.tx_count ?? 0) ? -1 : (a.tx_count ?? 0) < (b.tx_count ?? 0) ? 1 : 0)
                        .filter(predicate ?? ((value) => true))
                        .reverse()
                        .map(function (talkgroup) {
                            const labelId = `checkbox-list-secondary-label-${talkgroup.talkgroup_id}`;
                            return (
                                <ListItem
                                    key={talkgroup.talkgroup_id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={toggleSelectedTalkgroup(talkgroup.talkgroup_id)}
                                            checked={selectedTalkgroupIds.has(talkgroup.talkgroup_id)}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <Checkbox
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        onChange={toggleFavoriteTalkgroup(talkgroup.talkgroup_id)}
                                        checked={favoriteTalkgroupIds.has(talkgroup.talkgroup_id)}
                                    />
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={talkgroup.name ?? talkgroup.alias ?? ''}
                                            src={`${process.env.PUBLIC_URL}/${talkgroup.logo}`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        id={labelId}
                                        primary={`${talkgroup.name ?? talkgroup.alias} (${talkgroup.talkgroup_id})`}
                                        secondary={`${tx_emoji}${talkgroup.tx_count} ${radio_emoji}${talkgroup.radio_count}`}
                                    />
                                </ListItem>
                            )
                        })
                }
            </React.Fragment>
        )

    }

    return (
        <Modal
            open={params.talkgroupsModalOpen}
            onClose={handleTalkgroupsClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...style,
                width: '100%',
                maxWidth: 500,
                // maxHeight: 300,
                // bgcolor: 'background.paper',
                // overflow: 'auto'
            }}>
                <Box sx={{
                    width: '100%',
                    // maxWidth: 360,
                    maxHeight: 500,
                    bgcolor: 'background.paper',
                    overflow: 'auto'
                }}>
                    <Typography variant='overline'>Selected</Typography>
                    <Button
                        onClick={handleClearTalkgroups}
                        disabled={selectedTalkgroupIds.size === 0}
                    >
                        {selectedTalkgroupIds.size === 0 ? "Showing All Talkgroups" : "Clear Selections"}
                    </Button>
                    <List>
                        {
                            renderTalkgroupListItems((value) => selectedTalkgroupIds.has(value.talkgroup_id))
                        }
                    </List>
                    <Typography variant='overline'>Favorites</Typography>
                    <Button
                        onClick={handleListToFavorites}
                        disabled={selectedTalkgroupIds.intersection(favoriteTalkgroupIds).size === favoriteTalkgroupIds.size}
                    >
                        Select All
                    </Button>
                    <List>
                        {
                            renderTalkgroupListItems(
                                (value) =>
                                    favoriteTalkgroupIds.has(value.talkgroup_id) && !selectedTalkgroupIds.has(value.talkgroup_id)
                            )
                        }
                    </List>
                    <Typography variant='overline'>Available</Typography>
                    <List>
                        {
                            renderTalkgroupListItems(
                                (value) =>
                                    !(favoriteTalkgroupIds.has(value.talkgroup_id) || selectedTalkgroupIds.has(value.talkgroup_id))
                            )
                        }
                    </List>
                </Box>
            </Box>
        </Modal>
    );
}

export default TalkgroupsModal;
