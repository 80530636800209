import { Box, Paper, AppBar, Toolbar, styled, Badge } from "@mui/material";
import React from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import CallIcon from '@mui/icons-material/Call';
import Fab from '@mui/material/Fab';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TalkgroupsModal from "./TalkgroupsModal";
import TimeRangeModal from "./TimeRangeModal";
import RadiosModal from "./RadiosModal";
import { useStateContext } from "../context/StateContext";

export interface BottomAppBarParams {
}

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});


export default function BottomAppBar(params: BottomAppBarParams) {

    const { selectedRadioIds, selectedTalkgroupIds } = useStateContext();

    const [talkgroupsModalOpen, setTalkgroupsModalOpen] = React.useState(false);
    const handleTalkgroupsOpen = () => setTalkgroupsModalOpen(true);

    const [radiosModalOpen, setRadiosModalOpen] = React.useState(false);
    const handleRadiosOpen = () => setRadiosModalOpen(true);

    const [timeRangeModalOpen, setTimeRangeModalOpen] = React.useState(false);
    const handleTimeRangeOpen = () => setTimeRangeModalOpen(true);


    return (
        <Box
            sx={{
                pb: 7,
            }}
        >
            <Paper
                sx={{
                    // position: 'fixed',
                    // bottom: 0, 
                    // left: 0, 
                    // right: 0,
                }}
                elevation={3}
            >
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{
                        top: 'auto',
                        bottom: 0
                    }}
                    enableColorOnDark
                >
                    <Toolbar>
                        <StyledFab color="secondary" aria-label="add" sx={{ left: -140 }} onClick={handleTalkgroupsOpen}>
                            <Badge badgeContent={selectedTalkgroupIds.size}>
                                <GroupsIcon />
                            </Badge>
                        </StyledFab>
                        <StyledFab color="secondary" aria-label="add" sx={{}} onClick={handleRadiosOpen}>
                            <Badge badgeContent={selectedRadioIds.size}>
                                <CallIcon />
                            </Badge>
                        </StyledFab>
                        <StyledFab color="secondary" aria-label="add" sx={{ right: -140 }} onClick={handleTimeRangeOpen}>
                            <AccessTimeIcon />
                        </StyledFab>
                        <Box sx={{ flexGrow: 1 }} />
                    </Toolbar>
                </AppBar>
            </Paper>
            <TalkgroupsModal
                setTalkgroupsModalOpen={setTalkgroupsModalOpen}
                talkgroupsModalOpen={talkgroupsModalOpen}
            />
            <RadiosModal
                setRadiosModalOpen={setRadiosModalOpen}
                radiosModalOpen={radiosModalOpen}
            />
            <TimeRangeModal
                timeRangeModalOpen={timeRangeModalOpen}
                setTimeRangeModalOpen={setTimeRangeModalOpen}
            />
        </Box>
    );
}
